*{
  box-sizing: border-box;
}

header{
  width: 100%;
  height: 400px;
  background-clip: border-box;
  background-origin: border-box;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../public/images/imagenportada.jpg');
}

li{
  list-style: none;
}

a{
  text-decoration: none;
  color: blue !important;
}

a:hover{
  color: black !important;
}

.mysticky{
  position: sticky;
  top: 0;
}

.img{
  width: 100%;
}

@media screen and (min-width: 300px) {
  .img-rec{
    width: 100%;
    height: 300px;
  }
}

@media screen and (min-width: 500px) {
  .img-rec{
    width: 100%;
    height: 450px;
  }
}